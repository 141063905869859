<template>
  <n-config-provider :theme="theme" :theme-overrides="themeOverrides">
    <n-dialog-provider>
      <n-notification-provider>
        <!-- see https://www.naiveui.com/en-US/os-theme/components/message > MessageProvider Props -->
        <n-message-provider :duration="5000" :max="5" :keepAliveOnHover="true" :closable="true">
          <!-- <n-theme-editor> -->
          <slot name="default"></slot>
          <!-- </n-theme-editor> -->
        </n-message-provider>
      </n-notification-provider>
    </n-dialog-provider>
  </n-config-provider>
</template>

<script setup>
import { APP_STYLES_LIST } from '@/global/globalVariables';
import { useAppConfigStore } from '@/stores/app-config';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
// import { NThemeEditor } from 'naive-ui';

const appConfigStore = useAppConfigStore();
const { getTheme } = storeToRefs(appConfigStore);

const theme = computed(() => {
  return getTheme.value;
});

const themeOverrides = computed(() => {
  if (!theme.value) {
    return {
      common: {
        primaryColor: APP_STYLES_LIST.primaryColor,
        primaryColorHover: APP_STYLES_LIST.primaryColorHover,
        primaryColorPressed: APP_STYLES_LIST.primaryColorPressed,
        primaryColorFocus: APP_STYLES_LIST.primaryColorPressed,
        infoColor: APP_STYLES_LIST.infoColor,
        infoColorHover: APP_STYLES_LIST.infoColorHover,
        infoColorPressed: APP_STYLES_LIST.infoColorPressed,
        infoColorFocus: APP_STYLES_LIST.infoColorPressed,
        successColor: APP_STYLES_LIST.successColor,
        successColorHover: APP_STYLES_LIST.successColorHover,
        successColorPressed: APP_STYLES_LIST.successColorPressed,
        successColorFocus: APP_STYLES_LIST.successColorPressed,
        errorColor: APP_STYLES_LIST.errorColor,
        errorColorHover: APP_STYLES_LIST.errorColorHover,
        errorColorPressed: APP_STYLES_LIST.errorColorPressed,
        errorColorFocus: APP_STYLES_LIST.errorColorPressed,
        warningColor: APP_STYLES_LIST.warningColor,
        warningColorHover: APP_STYLES_LIST.warningColorHover,
        warningColorPressed: APP_STYLES_LIST.warningColorPressed,
        warningColorFocus: APP_STYLES_LIST.warningColorPressed
      },
      Button: {
        borderRadiusTiny: '4px',
        borderRadiusSmall: '4px',
        borderRadiusMedium: '6px',
        borderRadiusLarge: '8px',
        fontSizeTiny: '14px',
        fontSizeSmall: '14px',
        fontSizeMedium: '15px',
        fontSizeLarge: '16px',
        paddingTiny: '4px 5px',
        paddingSmall: '4px 10px',
        paddingMedium: '6px 15px',
        paddingLarge: '10px 30px',
        heightTiny: '31px',
        heightSmall: '31px',
        heightMedium: '36px',
        heightLarge: '43px',
        // default background color
        color: '#fcfcfc',
        colorHover: '#e3e3e3',
        colorPressed: '#d1d1d1',
        colorFocus: '#d1d1d1',
        textColor: APP_STYLES_LIST.infoColor,
        textColorTertiary: APP_STYLES_LIST.infoColor, // tertiary
        textColorHover: APP_STYLES_LIST.infoColor,
        textColorPressed: APP_STYLES_LIST.infoColor,
        textColorFocus: APP_STYLES_LIST.infoColor,
        textColorDisabled: APP_STYLES_LIST.infoColor,
        border: `1px solid #ddd`,
        borderHover: `1px solid #bebebe`,
        borderPressed: `1px solid #9d9d9d`,
        borderFocus: `1px solid #9d9d9d`
      },
      Menu: {
        //  menu item bg color
        color: APP_STYLES_LIST.tabBgColor,
        // menu item bg color
        itemColorHover: APP_STYLES_LIST.white,
        itemColorActive: APP_STYLES_LIST.infoColor,
        itemColorActiveHover: APP_STYLES_LIST.infoColor,
        // menu item text color
        itemTextColor: APP_STYLES_LIST.infoColor,
        itemTextColorActive: APP_STYLES_LIST.white,
        itemTextColorActiveHover: APP_STYLES_LIST.white,
        // menu item icon color
        itemIconColor: APP_STYLES_LIST.infoColor,
        itemIconColorActive: APP_STYLES_LIST.infoColor,
        itemIconColorActiveHover: APP_STYLES_LIST.infoColor,
        itemIconColorChildActive: APP_STYLES_LIST.infoColor,
        itemIconColorChildActiveHover: APP_STYLES_LIST.infoColor,
        arrowColor: APP_STYLES_LIST.infoColor,
        arrowColorActive: APP_STYLES_LIST.infoColor,
        // menu groups
        itemTextColorChildActive: APP_STYLES_LIST.infoColor,
        itemTextColorChildActiveHover: APP_STYLES_LIST.infoColor,
        dividerColor: '#e6e6e6'
      },
      Layout: {
        color: '#f2f2f2'
      },
      Card: {
        borderRadius: APP_STYLES_LIST.alertBorderRadius
      },
      Tabs: {
        tabTextColorCard: APP_STYLES_LIST.infoColor,
        tabTextColorActiveCard: APP_STYLES_LIST.black,
        tabColor: APP_STYLES_LIST.tabBgColor,
        tabBorderRadius: APP_STYLES_LIST.alertBorderRadius,
        panePaddingMedium: '20px 0 0 0'
      },
      Alert: {
        borderRadius: APP_STYLES_LIST.alertBorderRadius,
        padding: '12px',
        iconSize: '20px',
        iconMargin: '12px 10px 0 10px',
        // info alert
        colorInfo: '#def2fe',
        borderInfo: `1px solid #def2fe`,
        iconColorInfo: '#5abefb',
        contentTextColorInfo: APP_STYLES_LIST.infoColor,
        // warning alert
        colorWarning: '#f8e6d1',
        borderWarning: `1px solid #f8e6d1`,
        iconColorWarning: '#db841a',
        contentTextColorWarning: '#72450e',
        // success alert
        colorSuccess: '#d0efd9',
        borderSuccess: `1px solid #bde9ca`,
        iconColorSuccess: '#0a5b22',
        contentTextColorSuccess: '#0a5b22',
        // error alert
        colorError: '#f7d2d1',
        borderError: `1px solid #f4c0be`,
        iconColorError: '#d91e18',
        contentTextColorError: '#71100c'
      },
      Breadcrumb: {
        fontSize: '12px'
      },
      Switch: {
        railColor: '#c1c1c1'
      }
    };
  } else {
    return {
      common: {
        primaryColor: APP_STYLES_LIST.primaryColor,
        primaryColorHover: APP_STYLES_LIST.primaryColorHover,
        primaryColorPressed: APP_STYLES_LIST.primaryColorPressed
      },
      Button: {
        borderRadiusMedium: '6px',
        borderRadiusLarge: '6px',
        borderRadiusSmall: '6px',
        heightLarge: '42px',
        heightMedium: '36px',
        heightSmall: '30px',
        heightTiny: '24px',
        paddingTiny: '0 10px',
        paddingSmall: '0 14px',
        paddingLarge: '0 20px',
        paddingMedium: '0 16px'
      },
      Layout: {
        color: '#38383DFF'
      },
      Card: {
        borderRadius: '6px'
      }
    };
  }
});

onMounted(() => {
  const theme = localStorage.getItem('app-config') ? JSON.parse(localStorage.getItem('app-config')).theme : null;

  appConfigStore.setTheme({ theme: theme });
});
</script>
